const FREE_TOOLS = [
  {
    name: 'Caption generator',
    title: 'Free AI Caption Generator for Social Media',
    subTitle:
      "Skip the brainstorm and fast-forward to the good part — your post going viral on social media — with this AI social media post generator. It's your new shortcut to more engagement, more sales, and more high-fives from your boss.",
    description:
      'Short on time? Use AI to get help writing captions for six different social networks, including Facebook, Twitter, LinkedIn, and TikTok.',
    path: '/social-media-tools/ai-caption-generator-social-media/',
    article: 'internal-caption-generator',
    ui_key: 'internal-caption-generator',
    type: 'caption',
  },

  {
    name: 'Google Business caption generator',
    title: 'Free AI Instagram Caption Generator for Social Media',
    subTitle:
      'Save time by automating the writing process with this awesome Google Business caption maker. Try it for free right now.',
    description: 'Generate Google Business captions in seconds with this AI-powered Instagram caption writer. ',
    path: '/social-media-tools/ai-google-business-caption-generator/',
    article: 'internal-google-business-caption-generator',
    ui_key: 'internal-google-business-caption-generator',
    network: 'google business',
    type: 'caption',
  },

  {
    name: 'Instagram caption generator',
    title: 'Free AI Instagram Caption Generator for Social Media',
    subTitle:
      'Save time by automating the writing process with this awesome Instagram caption maker. Try it for free right now.',
    description: 'Generate Instagram captions in seconds with this AI-powered Instagram caption writer. ',
    path: '/social-media-tools/ai-instagram-caption-generator/',
    article: 'internal-instagram-caption-generator',
    ui_key: 'internal-instagram-caption-generator',
    network: 'instagram',
    type: 'caption',
  },

  {
    name: 'LinkedIn post generator',
    title: 'Generate content fast with this LinkedIn post generator',
    subTitle:
      'Write LinkedIn posts like a boss with this free LinkedIn post generator. Fill your content calendar fast, promote a new release or special event, and establish yourself as a LinkedIn all-star. It only takes a few seconds to generate a list of posts you can use right now.',
    description:
      'This AI caption writer is the key to helping you become the next big LinkedIn-fluencer or thought leader.',
    path: '/social-media-tools/ai-linkedin-post-generator/',
    article: 'internal-linkedin-caption-generator',
    ui_key: 'internal-linkedin-caption-generator',
    network: 'linkedin',
    type: 'caption',
  },

  {
    name: 'Facebook caption generator',
    title: 'Write captions fast with this Facebook post caption generator',
    subTitle:
      'Ready to breeze through your to-write list at record speed? Let this caption generator help you fill your content calendar fast (and watch it quickly become your new most-clicked bookmark).',
    description:
      'Fill your content calendar fast with this AI-powered Facebook post generator. Get creative and write your content in half the time.',
    path: '/social-media-tools/ai-facebook-post-caption-generator/',
    article: 'internal-facebook-caption-generator',
    ui_key: 'internal-facebook-caption-generator',
    network: 'facebook',
    type: 'caption',
  },
  {
    name: 'Pinterest caption generator',
    title: 'Write captions fast with this Pinterest pin caption generator',
    subTitle:
      'Ready to breeze through your to-write list at record speed? Let this caption generator help you fill your content calendar fast (and watch it quickly become your new most-clicked bookmark).',
    description:
      'Fill your content calendar fast with this AI-powered Pin caption generator. Get creative and write your content in half the time.',
    path: '/social-media-tools/ai-pinterest-caption-generator/',
    article: 'internal-pinterest-caption-generator',
    ui_key: 'internal-pinterest-caption-generator',
    network: 'pinterest',
    type: 'caption',
  },

  {
    name: 'Reddit caption generator',
    title: 'Write captions fast with this Reddit caption generator',
    subTitle:
      'Ready to breeze through your to-write list at record speed? Let this caption generator help you fill your content calendar fast (and watch it quickly become your new most-clicked bookmark).',
    description:
      'Fill your content calendar fast with this AI-powered Reddit caption generator. Get creative and write your content in half the time.',
    path: '/social-media-tools/ai-reddit-caption-generator/',
    article: 'internal-reddit-caption-generator',
    ui_key: 'internal-reddit-caption-generator',
    network: 'reddit',
    type: 'caption',
  },

  {
    name: 'TikTok caption generator',
    title: 'Write captions fast with this TikTok video caption generator',
    subTitle:
      'Ready to breeze through your to-write list at record speed? Let this caption generator help you fill your content calendar fast (and watch it quickly become your new most-clicked bookmark).',
    description:
      'Fill your content calendar fast with this AI-powered TikTok caption generator. Get creative and write your content in half the time.',
    path: '/social-media-tools/ai-tiktok-caption-generator/',
    article: 'internal-tiktok-caption-generator',
    ui_key: 'internal-tiktok-caption-generator',
    network: 'tiktok',
    image: './static/images/integrations/facebook/header_picture.webp',
    type: 'caption',
  },

  {
    name: 'Tweet generator',
    title: 'Free AI Twitter Caption Generator for Social Media',
    subTitle: 'Make the most out of 280 characters and keep your feed poppin with this lightning-fast tweet generator.',
    description:
      'This AI-powered tweet generator can help you come up with viral-worthy tweets to keep your feed exciting.',
    path: '/social-media-tools/ai-tweet-generator/',
    article: 'internal-twitter-caption-generator',
    ui_key: 'internal-twitter-caption-generator',
    network: 'twitter',
    type: 'caption',
  },

  {
    name: 'YouTube caption generator',
    title: 'Write captions fast with this YouTube video caption generator',
    subTitle:
      'Ready to breeze through your to-write list at record speed? Let this caption generator help you fill your content calendar fast (and watch it quickly become your new most-clicked bookmark).',
    description:
      'Fill your content calendar fast with this AI-powered YouTube video caption generator. Get creative and write your content in half the time.',
    path: '/social-media-tools/ai-youtube-caption-generator/',
    article: 'internal-youtube-caption-generator',
    ui_key: 'internal-youtube-caption-generator',
    network: 'youtube',
    type: 'caption',
  },

  {
    name: 'Hashtag generator',
    title: 'Free AI Hashtag Generator for Social Media',
    subTitle:
      'Hashtags are like turbochargers for your social media posts, but who has time to build them from scratch? Now you can automate the process with this free hashtag generator.',
    description:
      'Turbocharge your social media posts with this free social media hashtag generator for multiple networks.',
    path: '/social-media-tools/hashtag-generator/',
    article: 'internal-hashtag-generator',
    ui_key: 'internal-hashtag-generator',
    type: 'hashtag',
  },
  {
    // here Roman changed from instagram variable to facebook
    name: 'Facebook hashtag generator',
    title: 'Get more reach with our Facebook hashtag generator',
    subTitle:
      'Everybody knows hashtags are social media’s secret sauce, but who has time to come up with them from scratch? This handy hashtag helper can do it for you.',
    description: 'Give your feed posts, Reels, and Stories a dash of viral magic with this Facebook hashtag generator.',
    path: '/social-media-tools/internal-facebook-hashtag-generator/',
    article: 'internal-facebook-hashtag-generator',
    ui_key: 'internal-facebook-hashtag-generator',
    network: 'facebook',
    type: 'hashtag',
  },
  {
    name: 'Instagram hashtag generator',
    title: 'Get more reach with our Instagram hashtag generator',
    subTitle:
      'Everybody knows hashtags are social media’s secret sauce, but who has time to come up with them from scratch? This handy hashtag helper can do it for you.',
    description:
      'Give your feed posts, Reels, and Stories a dash of viral magic with this Instagram hashtag generator.',
    path: '/social-media-tools/instagram-hashtag-generator/',
    article: 'internal-instagram-hashtag-generator',
    ui_key: 'internal-instagram-hashtag-generator',
    network: 'instagram',
    type: 'hashtag',
  },
  {
    name: 'Twitter hashtag generator',
    title: 'Get more reach with our Twitter hashtag generator',
    subTitle:
      'Everybody knows hashtags are social media’s secret sauce, but who has time to come up with them from scratch? This handy hashtag helper can do it for you.',
    description: 'Give your feed posts, Reels, and Stories a dash of viral magic with this Twitter hashtag generator.',
    path: '/social-media-tools/twitter-hashtag-generator/',
    article: 'internal-twitter-hashtag-generator',
    ui_key: 'internal-twitter-hashtag-generator',
    network: 'twitter',
    type: 'hashtag',
  },
  {
    name: 'LinkedIn hashtag generator',
    title: 'Get more reach with our LinkedIn hashtag generator',
    subTitle:
      'Everybody knows hashtags are social media’s secret sauce, but who has time to come up with them from scratch? This handy hashtag helper can do it for you.',
    description: 'Give your feed posts, Reels, and Stories a dash of viral magic with this LinkedIn hashtag generator.',
    path: '/social-media-tools/linkedin-hashtag-generator/',
    article: 'internal-linkedin-hashtag-generator',
    ui_key: 'internal-linkedin-hashtag-generator',
    network: 'twitter',
    type: 'hashtag',
  },
  {
    name: 'TikTok hashtag generator',
    title: 'Get more reach with our TikTok hashtag generator',
    subTitle:
      'Everybody knows hashtags are social media’s secret sauce, but who has time to come up with them from scratch? This handy hashtag helper can do it for you.',
    description: 'Give your feed posts, Reels, and Stories a dash of viral magic with this TikTok hashtag generator.',
    path: '/social-media-tools/tiktok-hashtag-generator/',
    article: 'internal-tiktok-hashtag-generator',
    ui_key: 'internal-tiktok-hashtag-generator',
    network: 'tiktok',
    type: 'hashtag',
  },

  {
    name: 'Username generator',
    title: 'Need a new handle fast? Try our free username generator',
    subTitle:
      "Not sure what to call yourself online? Vista Social's instant username generator will give you username ideas that perfectly suit your internet persona (or the one you’re scheming up).",
    description:
      'Invent (or reinvent) your online persona with this AI-powered handle generator for social media networks, forums, gaming sites, and more.',
    path: '/social-media-tools/username-generator/',
    article: 'internal-username-generator',
    ui_key: 'internal-username-generator',
    type: 'username',
  },
  {
    name: 'Instagram username generator',
    title: 'Need a new handle fast? Try our Instagram name generator',
    subTitle:
      'Come up with a brand-new Instagram handle in five seconds flat, no questions asked. (Just kidding, there are a couple of questions, but it seriously only takes a few seconds)',
    description:
      "Get a list of Instagram name ideas in a few seconds flat. It's the perfect tool for businesses or individuals looking to nail it on Insta.",
    path: '/social-media-tools/instagram-name-generator/',
    article: 'instagram-instagram-username-generator',
    ui_key: 'instagram-instagram-username-generator',
    network: 'instagram',
    type: 'username',
  },

  {
    name: 'Twitter username generator',
    title: 'Need a new handle fast? Try our Twitter name generator',
    subTitle:
      'Come up with a brand-new Twitter handle in five seconds flat, no questions asked. (Just kidding, there are a couple of questions, but it seriously only takes a few seconds)',
    description:
      "Get a list of Twitter name ideas in a few seconds flat. It's the perfect tool for businesses or individuals looking to nail it on Twitter.",
    path: '/social-media-tools/twitter-name-generator/',
    article: 'internal-twitter-username-generator',
    ui_key: 'internal-twitter-username-generator',
    network: 'twitter',
    type: 'username',
  },

  {
    name: 'TikTok username generator',
    title: 'Need a new handle fast? Try our TikTok name generator',
    subTitle:
      'Come up with a brand-new TikTok handle in five seconds flat, no questions asked. (Just kidding, there are a couple of questions, but it seriously only takes a few seconds)',
    description:
      "Get a list of TikTok name ideas in a few seconds flat. It's the perfect tool for businesses or individuals looking to nail it on TikTok.",
    path: '/social-media-tools/tiktok-name-generator/',
    article: 'internal-tiktok-username-generator',
    ui_key: 'internal-tiktok-username-generator',
    network: 'tiktok',
    type: 'username',
  },

  {
    name: 'Text to Emoji translator',
    title: 'Translate text to emoji with this emoji translator',
    subTitle:
      '👋 🆕 ❤️ 🆓 ⚒️ = Welcome to your new favorite free tool! This emoji generator will help you create the best set of emojis to use in your posts.',
    description:
      'Translate text to emoji (including English, French, Spanish, Italian, and German) with this free emoji translator.',
    path: '/social-media-tools/text-to-emoji-translator/',
    article: 'internal-text-to-emoji',
    ui_key: 'internal-text-to-emoji',
    type: 'other-text-to-emoji',
  },

  {
    name: 'Emoji to text translator',
    title: 'Translate emoji to text this emoji translator',
    subTitle:
      '👋 🆕 ❤️ 🆓 ⚒️ = Welcome to your new favorite free tool! This emoji translator will help you decipher even the most complex strings of emojis so you’re never out of the loop. Use it to translate messages and get emoji definitions on the fly.',
    description:
      'Translate  emoji to text (including English, French, Spanish, Italian, and German) with this free emoji translator.',
    path: '/social-media-tools/emoji-to-text-translator/',
    article: 'internal-emoji-to-text',
    ui_key: 'internal-emoji-to-text',
    type: 'other-emoji-to-text',
  },

  {
    name: 'Social media glossary',
    description:
      'Define industry terms and lingo, from “organic reach” to “millennial pause.” Because even the pros occasionally miss the latest buzzwords.',
    path: '/social-media-tools/glossary/',
    ui_key: 'social-media-glossary',
  },

  {
    name: 'Similarity checker',
    description:
      'Get help in designing posts that are substantially different and can pass policy enforcement on Twitter',
    path: '/social-media-tools/similarity-checker/',
    ui_key: 'similarity-checker',
  },
  {
    name: 'QR Code generator',
    description:
      'A state-of-the-art solution to make every link a point of connection between your content and your audience',
    path: '/social-media-tools/qr-code-generator/',
    ui_key: 'qr-code-generator',
  },
  {
    name: 'Brand voice generator',
    description: 'Brand voice is the unique personality that your brand presents to the world',
    path: '/social-media-tools/brand-voice-generator/',
    ui_key: 'brand-voice-generator',
  },
]

module.exports = {
  FREE_TOOLS,
}
